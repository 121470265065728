import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ArticleCard = makeShortcode("ArticleCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<ArticleCard>`}</inlineCode>{` component should generally be used inside of a `}<inlineCode parentName="p">{`<Row>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` with a `}<inlineCode parentName="p">{`noGutterMdLeft`}</inlineCode>{` prop on the `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` to allow it to “hang” to the left.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard subTitle="subTitle" title="Title" author="Josh Black" date="April 29, 2019" readTime="Read time: 5 min" href="/" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
              "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard title="Explore & Create" author="Josh Black" href="https://www.ibm.com" actionIcon="arrowRight" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
              "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard title="Explore & Create a longer title example in this space" href="https://www.ibm.com" disabled mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
              "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard title="Explore & Create" color="dark" href="https://www.ibm.com" actionIcon="download" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
              "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard subTitle="subTitle" title="Explore & Create" author="Josh Black" readTime="Read time: 5 min" color="dark" href="https://www.ibm.com" actionIcon="email" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
              "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard subTitle="subTitle" title="Explore & Create" author="Josh Black" date="April 29, 2019" readTime="Read time: 5 min" color="dark" disabled mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png",
              "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/Article_05.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
    </Row>
    <h2>{`Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/ArticleCard/ArticleCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ArticleCard",
        "path": "components/ArticleCard/ArticleCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ArticleCard"
      }}>{`<Row>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      subTitle="subTitle"
      title="Title"
      author="Josh Black"
      date="April 29, 2019"
      readTime="Read time: 5 min"
      href="/"
    >
      ![Dark article layout mockup](/images/Article_05.png)
    </ArticleCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      title="Explore & Create"
      author="Josh Black"
      href="https://www.ibm.com"
      actionIcon="arrowRight"
    >
      ![Dark article layout mockup](/images/Article_05.png)
    </ArticleCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      title="Explore & Create a longer title example in this space"
      href="https://www.ibm.com"
      disabled
    >
      ![Dark article layout mockup](/images/Article_05.png)
    </ArticleCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      title="Explore & Create"
      color="dark"
      href="https://www.ibm.com"
      actionIcon="download"
    >
      ![Dark article layout mockup](/images/Article_05.png)
    </ArticleCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      title="Explore & Create"
      author="Josh Black"
      readTime="Read time: 5 min"
      color="dark"
      href="https://www.ibm.com"
      actionIcon="email"
    >
      ![Dark article layout mockup](/images/Article_05.png)
    </ArticleCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      title="Explore & Create"
      author="Josh Black"
      date="April 29, 2019"
      readTime="Read time: 5 min"
      color="dark"
      disabled
    >
      ![Dark article layout mockup](/images/Article_05.png)
    </ArticleCard>
  </Column>
</Row>
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use 32x32 image as child, will display in bottom left of card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set url for card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Card title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subTitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Card smaller sub title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`author`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Author of article`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date article published`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`readTime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Read time of article`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`launch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action icon, default is launch, options are `}<inlineCode parentName="td">{`Launch`}</inlineCode>{`, `}<inlineCode parentName="td">{`ArrowRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`Download`}</inlineCode>{`, `}<inlineCode parentName="td">{`Disabled`}</inlineCode>{`, `}<inlineCode parentName="td">{`Email`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`light`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to `}<inlineCode parentName="td">{`dark`}</inlineCode>{` for dark background`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set for disabled card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      